// This controller handled events on the subscriber form

import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "quantityField", "updateField", "price", "totalPrice"]
  static values = {
    price: Number
  }

  connect() {
    // event.preventDefault()
    console.log("Hello from LineController!");
    this.totalPriceTarget.innerHTML = "€ " + (parseInt(this.quantityFieldTarget.value) * this.priceValue).toFixed(2).replace(".", ",")
  }


  update_quantity(event) {
  	event.preventDefault()
    // this.totalPriceTarget.innerHTML = parseInt(this.quantityFieldTarget.value) * this.priceTarget.innerHTML
    this.totalPriceTarget.innerHTML = "€ " + (parseInt(this.quantityFieldTarget.value) * this.priceValue).toFixed(2).replace(".", ",")
  }
}