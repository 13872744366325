// This controller handled events on the subscriber form

import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "totalPrice","grandTotal"]

  connect() {
    // event.preventDefault()
    console.log("Hello from OrderController!")
    }
  
  
  update_total(event) {
    event.preventDefault()
    var total = 0.0
    this.totalPriceTargets.forEach(
      target => {
        total += parseFloat((target.innerHTML).slice(2))
        }
      )
    this.grandTotalTarget.innerHTML = "€ " + total.toFixed(2).replace(".", ",");
    }
}
