// This controller handled events on the subscriber form

import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "check_landowner", "check_call_back", "text_field_tel", "text_field_email", "footer_field_email", "footer_submit", "check_newsletter" ]

  connect() {
    // event.preventDefault()
    if (window.location.hash == "#join") {
      $('#newsletterModal').modal()
    }
    if (window.location.hash == "#callback") {
      $('#newsletterModal').modal()
      this.check_call_backTarget.checked = true
      this.text_field_telTarget.classList.toggle("d-none")
    }
    if (window.location.hash == "#land") {
      $('#newsletterModal').modal()
      this.check_landownerTarget.checked = true
    }
  }

  toggle_tel(event) {
  	event.preventDefault()
  	this.text_field_telTarget.classList.toggle("d-none")
  }

  open_modal_from_footer(event) {
  	// open the newsletter_subscribe_modal
  	event.preventDefault()
  	$('#newsletterModal').modal()
	  // fill the email-text-field with the value from the footer-field
  	this.text_field_emailTarget.value = this.footer_field_emailTarget.value
  	// check the newsletter check-box
  	this.check_newsletterTarget.checked = true
  }

}