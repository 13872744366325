// Load all the channels within this directory and all subdirectories.
// Channel files must be named *_channel.js.

const channels = require.context('.', true, /_channel\.js$/)
channels.keys().forEach(channels)


document.addEventListener('turbolinks:load', () => {

function ShowCallBack() {
  var checkBox = document.getElementById("check_call_back");
  var textField = document.getElementById("subscriber_tel");
  console.log("checked initialize")
  if (checkBox.checked == true) {
    textField.style.display = "block";
    console.log("checked!")
  } else {
     textField.style.display = "none";
  }
}

})